import { Fragment } from "react";
import { BrowserRouter } from "react-router-dom";
import "./App.scss";
import Routers from "./routers/Routers";
import Navbar from "./components/Navbar/Navbar";
// import UserList from "./components/UserList";

function App() {
  
  return (
    <Fragment>
      <Navbar />
      <BrowserRouter>
        <Routers/>
      </BrowserRouter>
      {/* <UserList/>   */}
    </Fragment>
  );
}

export default App;


// import Routers from "./Routers";

// function App() {
//   return (
//     <BrowserRouter>
//       <Routers />
//     </BrowserRouter>
//   );
// }

// export default App;



// import { useSelector, useDispatch } from "react-redux";
// import { decrement, increment } from "./redux/counterSlice";


// const value = useSelector((store) => store.counter.value);
// console.log(value);

// const dispatch = useDispatch()

// return (
  //   <div>
  //     <div>
  //       {value}
  //     </div>
  //     <div><button onClick={() => dispatch(increment())}>Arttır</button></div>
  //     <div><button onClick={() => dispatch(decrement())}>Azalt</button></div>
  //   </div>
  // );