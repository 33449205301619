import React, { useState } from "react";
import { FcGoogle } from "react-icons/fc";
import { TiVendorApple } from "react-icons/ti";
import { useNavigate } from "react-router-dom";
import "./Register.scss";

// .env dosyasındaki BASE_URL değişkenini kullanarak API URL'lerini ayarlıyoruz
const baseUrl = process.env.REACT_APP_BASE_URL;

const endpoints = {
  register: `${baseUrl}/register`,
  googleRegister: `${baseUrl}/register/google`,
  appleRegister: `${baseUrl}/register/apple`,
};

const Register = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    password: "",
    confirmPassword: "",
    isMaster: false,
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Şifrelerin uyuşmazlığını kontrol et
    if (formData.password !== formData.confirmPassword) {
      alert("Passwords do not match.");
      return;
    }

    // Email formatını kontrol et
    if (!formData.email || !/\S+@\S+\.\S+/.test(formData.email)) {
      alert("Please enter a valid email.");
      return;
    }

    // Şifre uzunluğunu kontrol et
    if (formData.password.length < 6) {
      alert("Password must be at least 6 characters.");
      return;
    }

    try {
      const response = await fetch(endpoints.register, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(formData),
      });

      const data = await response.json();

      if (data.success) {
        alert("Registration successful!");
        setFormData({
          fullName: "",
          email: "",
          password: "",
          confirmPassword: "",
          isMaster: false,
        });  // Formu sıfırla
        navigate("/login");  // Login sayfasına yönlendir
      } else {
        alert("An error occurred: " + data.message);
      }
    } catch (error) {
      console.error("Registration error:", error);
    }
  };

  const handleGoogleRegister = async () => {
    try {
      const token = "osman"; // Google'dan aldığınız token'ı buraya ekleyin
      const response = await fetch(endpoints.googleRegister, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ token }),
      });

      const data = await response.json();
      if (data.success) {
        alert("Registered with Google successfully!");
        navigate("/login");  // Yönlendirme
      } else {
        alert("An error occurred: " + data.message);
      }
    } catch (error) {
      console.error("Google registration error:", error);
    }
  };

  const handleAppleRegister = async () => {
    try {
      const token = "your-apple-auth-token"; // Apple'dan aldığınız token'ı buraya ekleyin
      const response = await fetch(endpoints.appleRegister, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ token }),
      });

      const data = await response.json();
      if (data.success) {
        alert("Registered with Apple successfully!");
        navigate("/login");  // Yönlendirme
      } else {
        alert("An error occurred: " + data.message);
      }
    } catch (error) {
      console.error("Apple registration error:", error);
    }
  };

  return (
    <div className="register-container">
      <div className="register-card">
        <h2>Create an Account</h2>
        <p>Join us today and start your journey</p>
        <form onSubmit={handleSubmit}>
          <div className="input-group">
            <label htmlFor="fullName">Full Name</label>
            <input
              type="text"
              id="fullName"
              name="fullName"
              placeholder="Enter your full name"
              value={formData.fullName}
              onChange={handleChange}
            />
          </div>
          <div className="input-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              placeholder="Enter your email"
              value={formData.email}
              onChange={handleChange}
            />
          </div>
          <div className="input-group">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              id="password"
              name="password"
              placeholder="Create a password"
              value={formData.password}
              onChange={handleChange}
            />
          </div>
          <div className="input-group">
            <label htmlFor="confirmPassword">Confirm Password</label>
            <input
              type="password"
              id="confirmPassword"
              name="confirmPassword"
              placeholder="Confirm your password"
              value={formData.confirmPassword}
              onChange={handleChange}
            />
          </div>
          <div className="input-group checkbox-group">
            <label htmlFor="isMaster">Are you a master?</label>
            <input
              type="checkbox"
              id="isMaster"
              name="isMaster"
              checked={formData.isMaster}
              onChange={handleChange}
            />
          </div>
          <button type="submit" className="register-button">
            Register
          </button>
        </form>

        <button onClick={handleGoogleRegister} className="social-register-button google">
          Register with Google <FcGoogle />
        </button>
        <button onClick={handleAppleRegister} className="social-register-button apple">
          Register with Apple <TiVendorApple />
        </button>

        <p className="login-link">
          Already have an account? <a href="/login">Login here</a>
        </p>
      </div>
    </div>
  );
};

export default Register;
