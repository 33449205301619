import React from "react";
import { Link } from "react-router-dom";
import "./Home.scss";

const Home = () => {
  return (
    <div className="main-container">
      <div className="first-temp">
        <h1>FindPro</h1>
        <h3><i>Best way to find job or master</i></h3>
        <div className="buttons">
          <Link to="/login">
            <button>Login</button>
          </Link>
          <Link to="/register">
            <button>Register</button>
          </Link>
        </div>
      </div>
      <div className="purpose">
        <h1>Neyi Amaçlıyoruz</h1>
      </div>
    </div>
  );
};

export default Home;
