import React from 'react';
import './Dashboard.scss';

const Dashboard = () => {
  return (
    <div className="dashboard">
      <h1 className="dashboard-title">Dashboard</h1>

      <div className="dashboard-quick-actions">
        <button className="action-btn">Profil Düzenle</button>
        <button className="action-btn">Ayarlar</button>
        <button className="action-btn">Yeni İçerik Ekle</button>
      </div>

      <div className="dashboard-stats">
        <div className="stat-card">
          <h3>Toplam Kullanıcı</h3>
          <p>150</p>
        </div>
        <div className="stat-card">
          <h3>Yeni Mesajlar</h3>
          <p>5</p>
        </div>
        <div className="stat-card">
          <h3>Bildirimler</h3>
          <p>12</p>
        </div>
      </div>

      <div className="dashboard-chart">
        {/* Grafik bileşenini buraya ekleyebilirsin */}
        <p>Grafik buraya gelecek</p>
      </div>

      <div className="dashboard-users">
        <h2>Son Aktif Kullanıcılar</h2>
        <ul>
          <li>Kullanıcı 1</li>
          <li>Kullanıcı 2</li>
          <li>Kullanıcı 3</li>
        </ul>
      </div>
    </div>
  );
};

export default Dashboard;
