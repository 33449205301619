import { configureStore } from '@reduxjs/toolkit';
import counterReducer from "../redux/counterSlice";
import UserReducer from "../redux/userSlice";
import authReducer from "./slices/authSlide";

export const store = configureStore({
  reducer: {
    counter : counterReducer,
    user : UserReducer,
    auth: authReducer,
  },
})